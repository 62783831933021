import React from "react";
import Meta from "./meta";
import Header from "./header";
import Footer from "./footer";
import "../styles/main.scss";
import "../scss/style.scss";
export default ({ isHomePage, children }) => {
  return (
    <div className={"main-container"}>
      <Meta/>
      <Header isHomePage={isHomePage}/>
      {children}
      <Footer/>
    </div>
  );
}
