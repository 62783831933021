import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
const Meta = () => (
    <Helmet>
        <title>E-CELL</title>
    </Helmet>
)

export default Meta;
