import React, { Component } from "react";
import chitkaraLogo from "../images/ceed/chitkara.png";
import logo from "../images/ecellpb/ecell-pb-logo.png";
import scrollToElement from "scroll-to-element";
import { Link } from "gatsby";

class Header extends Component {

  constructor (props) {
    super(props);
    this.state = {
      scrolled: false
    };
  }

  componentDidMount () {
    window.addEventListener("scroll", this.onScroll.bind(this));
    if (window.location.hash && window.location.hash !== "") {
      scrollToElement(window.location.hash);
    }
    this.listener = window.addEventListener("hashchange", this.scrollToWindowHash);
  }

  componentWillUnmount () {
    window.removeEventListener("hashchange", this.scrollToWindowHash);
  }

  scrollToWindowHash () {
    if (window.location.hash !== "") {
      scrollToElement(window.location.hash);
    }
  }

  scrollToMenu (id) {

    scrollToElement(id);
    document.querySelector(".navbar-toggler").click();
  }

  onScroll () {
    if (window.pageYOffset > 200) {
      this.setState({
        scrolled: true
      });
    } else {
      this.setState({
        scrolled: false
      });
    }
  }

  render () {
    const { scrolled } = this.state;
    const { isHomePage } = this.props;
    return (
      <header className="header-bar">
        <nav
          className={"navbar navbar-expand-lg navbar-light transparent-white fixed-top main-nav2 " + (scrolled ? "top-nav-collapse" : "")}>
          <div className="container">
            <div className="nav-box">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="ECELL" className="img-fluid"/>
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse"
                      data-target="#navbarNavDropdown"
                      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i className="ion-navicon"></i>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  {
                    isHomePage ? (
                      <a className="nav-link" onClick={this.scrollToMenu.bind(this, "#banner")}>Home</a>
                    ) : (
                      <Link
                        className="nav-link" to={"/"}>Home</Link>
                    )
                  }

                </li>
                <li className="nav-item">
                  {
                    isHomePage ? (
                      <a className="nav-link" onClick={this.scrollToMenu.bind(this, "#about")}>About</a>
                    ) : (
                      <Link className="nav-link"
                            to={"/#about"}>About</Link>
                    )
                  }
                </li>
                <li className="nav-item">
                  {
                    isHomePage ? (
                      <a className="nav-link" onClick={this.scrollToMenu.bind(this, "#mission")}>Mission</a>
                    ) : (
                      <Link className="nav-link"
                            to={"/#mission"}>Mission</Link>
                    )
                  }
                </li>
                <li className="nav-item">
                  {
                    isHomePage ? (
                      <a className="nav-link" onClick={this.scrollToMenu.bind(this, "#events")}>Events</a>
                    ) : (
                      <Link className="nav-link"
                            to={"/#mentors"}>Events</Link>
                    )
                  }
                </li>
                <li className="nav-item">
                  {
                    isHomePage ? (
                      <a className="nav-link" onClick={this.scrollToMenu.bind(this, "#team")}>Team</a>
                    ) : (
                      <Link className="nav-link"
                            to={"/#team"}>Team</Link>
                    )
                  }
                </li>
                <li className="nav-item">
                  {
                    isHomePage ? (
                      <a className="nav-link" onClick={this.scrollToMenu.bind(this, "#mentors")}>Mentors</a>
                    ) : (
                      <Link className="nav-link"
                            to={"/#mentors"}>Mentors</Link>
                    )
                  }
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <a className="navbar-parent-brand">
            <img src={chitkaraLogo} alt="CU"/>
          </a>
        </nav>
      </header>
    );
  }
}

export default Header;
