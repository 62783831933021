import React from "react";
import {Link} from 'gatsby'
import Layout from "../components/layout";
const spinningSelector='.contact-form';
const onSubmit = async function(e){
  e.preventDefault();
  const formData = Object.values(e.target).reduce((obj,field) => { obj[field.name] = field.value; return obj }, {});
  const {name,phone,email,message}=formData;
  window.startSpinning(spinningSelector);
  try{
    const response = await fetch('https://ceed-contact.herokuapp.com/api/emails', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        to:'piyush.sharma@laxaar.com',
        template:'contact',
        from:'enquiry@cuceed.org',
        data:{
          name,
          email,
          message,
          phone
        }
      })
    });
    console.log(await response.json());
    document.querySelector('#contact-form').style.display='none';
    document.querySelector('#success').style.display='block';
  }catch (e) {
    document.querySelector('#error').style.display='block';
  }
  window.stopSpinning(spinningSelector);
};
const ContactPage = () => (
  <Layout>
    <section id="contact-page-head" className="section general-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  text-center">
            <div className="page-heading">
              <h2 className="display-4">Contact Us</h2>

              <ul className="list-unstyled list-inline">
                <li className="list-inline-item"><Link to={"/"}>Home </Link></li>
                <li className="list-inline-item"><a>/</a></li>
                <li className="list-inline-item"><a href="#" className="text-muted">Contact Us</a></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact" className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center col-md-12">
            <div className="section-heading">
              <h2 className="display-4">Have any query?</h2>
              <p>We Would Love To Hear From You!</p>
            </div>
            <div className="error-message" id="error">
              <i className="ion-ios-close-outline"/>
              <p>Sorry Message is not sent</p>
            </div>
            <div className="success-message" id="success">
              <i className="ion-ios-checkmark-outline"/>
              <p>Message Sent</p>
            </div>
            <form  method={"POST"} className="contact-form" id="contact-form" onSubmit={onSubmit.bind(this)}>
              <div className="input-group">
                <input type="text" aria-label="Name" name="name" className="form-control"
                       placeholder="Type Your Name" required/>
                <input type="tel" aria-label="Phone" name="phone" className="form-control"
                       placeholder="Type Your Phone" required/>
              </div>
              <div className="form-group">
                <input type="email" className="form-control" name="email" placeholder="Type Your Email" required/>
              </div>
              <div className="form-group">
                <textarea name="message" cols="30" rows="6" className="form-control"
                          placeholder="Enter your Query" required/>
              </div>
              <button type={"submit"} className="btn btn-hero">Submit Query</button>
              <input type="hidden" name="_subject" value="Query regarding CAP"/>
              <input type="hidden" name="_template" value="table"/>

            </form>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
